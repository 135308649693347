
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FiArrowLeft } from "react-icons/fi";

import CheckoutForm from '../components/CheckoutForm';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';
import BASE_URL from '../config';

const stripePromise = loadStripe('pk_test_0erQNzYrhNahOtVUMB5L3kKV00Fbp33J0X');

export default function Checkout() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { getproduct, isLoading } = useContext(AppContext);
  const [isLoading2, setIsLoading2] = useState(false);
  const [product, setProduct] = useState(null);
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    fetchProduct();
    createPaymentIntent();
  }, []);

  const fetchProduct = async () => {
    const response = await getproduct({ productid: productId });
    setProduct(response[0]);
    setIsLoading2(false)
  }

  const createPaymentIntent = async () => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      const response = await axios.post(`${BASE_URL}/create-payment-intent`, {
        product: productId,
        userid: userToken.userid
      });
      setClientSecret(response.data.clientSecret);
    } catch (error) {
      console.error('Error creating PaymentIntent:', error);
    }
  };
  
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <main className='bg-white min-h-screen'>
      {isLoading ? (
        <div className='flex justify-center w-full py-12'>
          <Spinner color='#CCCCCC' size={24} />
        </div>
      ) : (
        <>
          {product ? (
            <div className='flex flex-row mx-auto min-h-screen'>
              <div className='lg:w-1/2 md:w2/2 py-16 flex-1'>
                <div className='lg:w-9/12 mx-auto md:w-1/1 space-y-10'>
                  <div className='flex justify-start'>
                    <Link
                      className='text-neutral-600 flex items-center space-x-2 font-semibold'
                      onClick={() => navigate(-1)}
                    >
                      <FiArrowLeft className='text-neutral-600' size={16} /> <span>Back</span>
                    </Link>
                  </div>
                  <div className='flex-row justify-between'>
                    <div className='flex flex-row space-x-5'>
                      <img
                        className='object-cover rounded w-32 h-32'
                        src={`data:image/png;base64,${product.image0}`}
                        alt=''
                      />
                      <div className='pt-1'>
                        <h2 className='mb-2 text-xl text-neutral-700 font-semibold'>{product.name}</h2>
                        <h2 className='text-4xl text-black font-bold tracking-wide'>
                          ${(product.price).toLocaleString()}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className='lg:w-7/12 lg:ml-auto md:w2/2'>
                    <div className='flex justify-between font-medium text-neutral-900'>
                      <div>Subtotal</div>
                      <div>${(product.price).toLocaleString()}</div>
                    </div>
                    <hr className='my-3' />
                    <div className='flex justify-between font-semibold text-neutral-500'>
                      <div>Service fee (10.0%)</div>
                      <div>${((product.price) / 10).toLocaleString()}</div>
                    </div>
                    <hr className='my-3' />
                    <div className='flex justify-between font-semibold text-neutral-900'>
                      <div>Total due</div>
                      <div>${((product.price) + ((product.price) / 10)).toLocaleString()}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='lg:w-1/2 md:w2/2 py-16 shadow-2xl flex-1 justify-center items-center'>
                {clientSecret ? (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm clientSecret={clientSecret} />
                  </Elements>
                ) : (
                  <div className='flex justify-center w-full py-12'>
                    <Spinner color='#CCCCCC' size={24} />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className='flex justify-center w-full py-12'>
              <Spinner color='#111111' size={24} />
            </div>
          )}
        </>
      )}
    </main>
  );
}
