import '../styles/auth.css'
import AuthLayout from '../components/AuthLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";


export default function ResetPassword() {

    const { token } = useParams();

    const [email, setEmail] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [passwordreset, setPasswordreset] = useState(false);
    const [error, setError] = useState('');
    const [resp, setResp] = useState('');

    const { resetpassword, validatetoken, isLoading } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        validateToken()
    }, [token]);
    
    const validateToken = async () => {
        const response = await validatetoken(token);
        setResp(response)
        // Update the state with the received products
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      try {
        if(newpassword !== "" && confirmpassword !== ""){
            if (newpassword.length < 6 || confirmpassword.length < 6) {
                setError('Password must have at least 6 characters');
                return;
            }
            else if (newpassword != confirmpassword) {
                setError('Passwords don\'t match');
                return;
            }
            else{
                setError('')
                const response = await resetpassword(token, newpassword, confirmpassword);
                if(response == 'success'){
                    setPasswordreset(true);
                }
            }    
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('failed: '+ error)
      }
    }  
    };

  return (
    <AuthLayout>
    <main>
        <section className='max-w-lg mx-auto pt-10'>
            <form onSubmit={handleSubmit} className='authform p-10 lg:px-16 mb-20'>
            {passwordreset === false ? 
            <>
                <h3 className='text-white text-2xl font-semibold text-center mb-2'>Reset your password</h3>
                {resp == 'success' || resp == '' ? <h6 className='text-neutral-400 text-md text-center mb-10'>Password must have at least 6 characters</h6> : '' }
                {resp == 'invalid' ? 
                <>
                    <h4 className='text-red-600 text-xl font-semibold text-center my-10'>Token Expired. Please Try Again!</h4>
                </> : <>
                <div className='border-2 fields border-white'>
                    <div className='py-2 px-3'>
                        <label for="newpassword">New Password</label>
                        <input type="password" id="newpassword" placeholder="******" value={newpassword} onChange={(e) => setNewPassword(e.target.value)} required/>
                    </div>
                    <div className='py-2 px-3 border-t-2 border-white'>
                        <label for="confirmpassword">Confirm New Password</label>
                        <input type="password" id="confirmpassword" placeholder="******" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} required/>
                    </div>
                </div>
                {error !== "" ? <div className='text-center text-red-500 font-semibold text-md my-3'>{error}</div> : <div className='py-4'></div>}
                <button type="submit" class="btn-main w-full" disabled={isLoading}>{isLoading === false ? 'Submit' : <Bounce animating={isLoading}/>}</button>
                </>}
            </>
            : 
            <>
            <h3 className='text-white text-2xl font-semibold text-center mb-4'>Password Updated Successfully</h3>
            <div className='text-neutral-400 text-center'>Your password has been reset. You can now log in to your account using your new password. If you have any further questions or need assistance, please feel free to contact our support team.</div>
            <div class="btn-main w-full uppercase text-center mt-10">Thank You</div>
            </>
            }
            </form>
        </section>
    </main>
    </AuthLayout>
  )
}
