import AppLayout from '../components/AppLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import '../styles/orders.css'
import { TbExternalLink } from 'react-icons/tb';


export default function Sales() {

    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const [sales, setSales] = useState([]);
    const [isLoading2, setIsLoading2] = useState(false);

    const { getmysales, isLoading } = useContext(AppContext);

    const navigate = useNavigate();

    useEffect(() => {
      const fetchMySales = async () => {
          const response = await getmysales();
          setSales(response);
      }

      fetchMySales();
    }, []);

  return (
    <AppLayout>
    <main>
      <div className='flex justify-between items-center mb-3'>
        <h3 className='text-white text-3xl font-semibold'>Sales</h3>
      </div>
      {
        isLoading === false ? 
          (sales && sales.length > 0) ?
          <div className='space-y-3'>
          {
            sales.map((order, index) => {
              const formattedDate = new Date(order.addedat).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
              return(
                <div key={index} className='lg:flex items-center justify-between order px-5 relative'>
                  { order.redeemed == 1 && <div className='absolute -right-5 -top-2 bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded'>Redeemed</div> }
                  <div className='grid lg:w-2/3 lg:grid-cols-4 md:grid-cols-2 md:w-full lg:gap-y-0 md:gap-y-5 sm:gap-y-6 py-5'>
                    <div><h6>Name</h6><p className='text-md flex items-center'>{order.productname} <Link className='text-blue-500 ml-2' to={`/product/${order.productid}`}><TbExternalLink size={18}/></Link></p></div>
                    <div><h6>Date</h6><p>{formattedDate}</p></div>
                    <div><h6>Amount</h6><p>${order.productprice}</p></div>
                    <div><h6>Buyer</h6><p>{order.buyer}</p></div>
                  </div>
                  <div className='flex lg:w-auto md:w-full pb-5 lg:pt-5 md:pt-0'>
                    <div><Link to={`/chat/${order.user}`} className='btn-open'>Chat</Link></div>
                  </div>
                </div>
              )
            }) 
          }
          </div>
          : <div className='text-neutral-200 text-center text-xl font-medium tracking-wide py-10'>No Sales Found</div>
        : 
        <div className='flex justify-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div>
        }
    </main>
    </AppLayout>
  )
}
