import React from 'react';
import AuthHeader from './AuthHeader';
import Footer from './Footer';

const AuthLayout = ({ children }) => {
  return (
    <html lang="en">
      <body className='' style={{ backgroundImage: `url('/images/authbg.png')`, backgroundRepeat: 'no-repeat', backgroundSize: '100vw 50vh' }}>
        <div className='flex min-h-screen flex-col items-center justify-between'>
        <AuthHeader />
        {children}
        <Footer />
        </div>
      </body>
    </html>
  );
};

export default AuthLayout;