import '../styles/auth.css'
import AuthLayout from '../components/AuthLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";


export default function Login() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ifredirect = (searchParams.get('redirect') != null) ? searchParams.get('redirect') : ''

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const { login, isLoading } = useContext(AuthContext);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      try {
        if(email !== "" && password !== ""){
        setError('')
        const response = await login(email, password);
        if(response == 'success'){
            if(ifredirect != ''){
                navigate(ifredirect);
            }
            else{
                navigate('/dashboard');
            } 
        }
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('Login failed: '+ error)
      }
    }  
    };

  return (
    <AuthLayout>
    <main>
        <section className='max-w-lg mx-auto pt-10'>
            <form onSubmit={handleSubmit} className='authform p-10 lg:px-16 mb-20'>
                <h3 className='text-white text-2xl font-semibold text-center mb-10'>Sign in</h3>
                <div className='border-2 fields border-white mb-5'>
                    <div class="py-2 px-3 border-b-2 border-white">
                        <label for="email">Email Address</label>
                        <input type="email" id="email" placeholder="johndoe@example.com" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    </div>
                    <div className='py-2 px-3'>
                        <label for="password">Password</label>
                        <input type="password" id="password" placeholder='******' value={password} onChange={(e) => setPassword(e.target.value)} required/>
                    </div>
                </div>
                <div class="text-right mb-0">
                    <Link className='text-neutral-500 text-md hover:underline' to="/forgot-password">Forgot Password?</Link>
                </div>
                {error !== "" ? <div className='text-center text-red-500 font-semibold text-md mb-3'>{error}</div> : <div className='py-4'></div>}
                <button type="submit" class="btn-main w-full" disabled={isLoading}>{isLoading === false ? 'Continue' : <Bounce animating={isLoading}/>}</button>
            </form>
        </section>
    </main>
    </AuthLayout>
  )
}
