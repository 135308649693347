import AppLayout from '../components/AppLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { Dialog, RadioGroup, Transition } from '@headlessui/react'


export default function Dashboard() {

    const [search, setSearch] = useState('');
    const [searchloading2, setSearchloading2] = useState(false);
    const [error, setError] = useState('');
    const [products, setProducts] = useState([]);
    const userToken = JSON.parse(localStorage.getItem('userToken'));
    const [productmodal, setProductmodal] = useState(false)

    const { isLoading, getmylistings, getproducts, searchproducts, searchloading } = useContext(AppContext);

    const navigate = useNavigate();

    const handleSearch = async (e) => {
      const searchTerm = e.target.value;
      setSearch(searchTerm);

      const response = await searchproducts(searchTerm);
      // Update the state with the received products
      setProducts(response);
    };
    

    useEffect(() => {
      const fetchMyListings = async () => {
          if(userToken.type == 'Buyer'){
          const response = await getproducts();
          // Update the state with the received products
          setProducts(response);
          }
          else if(userToken.type == 'Seller'){
            const response = await getmylistings();
            // Update the state with the received products
            setProducts(response);
          }
      }

      // Call the function and update the state when the component mounts
      fetchMyListings();
    }, []);

  return (
    <AppLayout>
      { userToken.type === 'Buyer' ? 
      <main>
        <div className='flex justify-between items-center mb-3'>
          <h3 className='text-white text-3xl font-semibold'>Dashboard</h3>
        </div>
        <div class="items-center justify-between w-full md:flex flex-1 md:order-1 mb-10" id="navbar-cta">
            <div class="relative w-full">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                {searchloading === true ? <Spinner className='w-5 h-5 text-neutral-300'/> : <svg class="w-5 h-5 text-neutral-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>}
                <span class="sr-only">Search icon</span>
            </div>
            <input type="text" id="search-navbar" class="block w-full p-3 pl-10 text-sm" placeholder="Search product title" value={search} onChange={handleSearch}/>
            </div>
        </div>
        {
        isLoading === false ? 
          <>
          { 
          products.length > 0 ? <>
          <div class="grid lg:grid lg:grid-cols-3 grid-cols-1 gap-4 gap-y-4">
          {
            products.map((product, index) => {
              return(
                <div key={index} className='flex-1'>
                <div className=" bg-neutral-700 rounded-lg shadow h-full flex flex-col hover:bg-neutral-800">
                    <Link to={`/product/${product.productid}`}>
                        <img class="rounded-t-lg object-cover w-full h-96" src={`data:image/png;base64,${product.image0}`} alt={product.name} />
                    </Link>
                    <div class="p-5 flex flex-1 flex-col justify-between">
                        <div>
                        <Link to={`/product/${product.productid}`}>
                            <h5 class="mb-2 text-2xl font-semibold text-white">{product.name}</h5>
                        </Link>
                        <p class="mb-3 text-sm text-neutral-400">{product.shortdescription}</p>
                        </div>
                        <div class="flex items-center justify-between mt-5">
                            <span class="text-2xl font-semibold text-white">${product.price}</span>
                            <Link to={`/product/${product.productid}`}><img src='./images/icons/Redo.png'/></Link>
                        </div>
                    </div>
                </div>
                </div>
              )
            }) 
          }
          </div>
          </>
          : <div className='text-white text-center text-2xl font-medium tracking-wide'>No Products found</div>
          }
          </>
        : 
        <div className='flex justify-center text-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div>
        }
      </main>
      : 
      <main>
        <div className='flex justify-between items-center mb-10'>
          <h3 className='text-white text-3xl font-semibold'>My store</h3>
          <div>
            <Link to="/add-listing" className='btn btn-main !px-14 !rounded-lg !font-semibold'>Add product</Link>
          </div>
        </div>
        {
        isLoading === false ?
        <div class="lg:grid lg:grid-cols-3 md:block flex gap-4">
          { 
          products.length > 0 ?
          products.map((product, index) => {
            return(
              <div className='flex-1'>
              <div className=" bg-neutral-700 rounded-lg shadow h-full flex flex-col hover:bg-neutral-800">
                  <Link to={`/product/${product.productid}`}>
                      <img class="rounded-t-lg object-cover w-full h-96" src={`data:image/png;base64,${product.image0}`} alt="" />
                  </Link>
                  <div class="p-5 flex flex-1 flex-col justify-between">
                      <div>
                      <Link to={`/product/${product.productid}`}>
                          <h5 class="mb-2 text-2xl font-semibold text-white">{product.name}</h5>
                      </Link>
                      <p class="mb-3 text-sm text-neutral-400">{product.shortdescription}</p>
                      </div>
                      <div class="flex items-center justify-between mt-5">
                          <span class="text-2xl font-semibold text-white">${product.price}</span>
                          <Link to={`/product/${product.productid}`}><img src='./images/icons/Redo.png'/></Link>
                      </div>
                  </div>
              </div>
              </div>
            )
          }) 
          : <div className='text-white text-center text-2xl font-medium tracking-wide'>No Products found</div>
          }
        </div>
        : 
        <div className='flex justify-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div>
        }
      </main>
      }
    </AppLayout>
  )
}
