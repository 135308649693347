import '../styles/header.css'
import { Link, useLocation } from 'react-router-dom';

export default function AuthHeader() {
    const location  = useLocation();
    const linkText = location.pathname === '/login' ? 'Sign Up' : 'Sign In';
    const linkDestination = location.pathname === '/login' ? '/signup' : '/login';
  
  return (
    <nav className='py-3 px-0 lg:px-5'>
    <div class="flex flex-wrap items-center justify-between mx-auto p-4">
    <Link to="/" class="flex items-center space-x-4">
        <img src='/logo.png' className='w-10 h-10'/>
        <span class="whitespace-nowrap nav-title uppercase">JustSell</span>
    </Link>
    <div class="flex md:order-2">
        <Link to={linkDestination} class="authnav-button">{linkText}</Link>
    </div>
    </div>
    </nav>
  )
}
