import AppLayout from '../components/AppLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import '../styles/settings.css'
import { HiArrowNarrowRight } from 'react-icons/hi';

export default function UpdatePassword() {

    const userToken = JSON.parse(localStorage.getItem('userToken'));
    const [oldpassword, setOldPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const { updatepassword, isLoading } = useContext(AppContext);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      try {
        if(oldpassword !== "" && newpassword !== "" && confirmpassword !== ""){
            if (oldpassword.length < 6 || newpassword.length < 6 || confirmpassword.length < 6) {
                setError('Password must have at least 6 characters');
                return;
            }
            else if (newpassword != confirmpassword) {
                setError('Passwords don\'t match');
                return;
            }
            else{
                setError('')
                const response = await updatepassword(oldpassword, newpassword, confirmpassword);
                if(response === 'success'){
                    setOldPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                    navigate('/settings')
                }
            }
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('Update failed: '+ error)
      }
    }  
    };

  return (
    <AppLayout>
    <main className='settings'>
      <div className='flex justify-between items-center mb-3'>
        <h3 className='text-white text-3xl font-semibold'>Update Password</h3>
      </div>
      <form onSubmit={handleSubmit} className='space-y-5 lg:w-1/2 md:w-full'>
        <div>
            <label for="oldpassword">Current Password</label>
            <input type="password" id="oldpassword" placeholder="******" value={oldpassword} onChange={(e) => setOldPassword(e.target.value)} required/>
        </div>
        <div>
            <div><hr className='border-neutral-800 mb-2'/></div>
            <label className='!capitalize !text-base'>Password must have at least 6 characters</label>
        </div>
        <div>
            <label for="newpassword">New Password</label>
            <input type="password" id="newpassword" placeholder="******" value={newpassword} onChange={(e) => setNewPassword(e.target.value)} required/>
        </div>
        <div>
            <label for="confirmpassword">Confirm New Password</label>
            <input type="password" id="confirmpassword" placeholder="******" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} required/>
        </div>
        {error !== "" ? <div className='text-center text-red-500 font-semibold text-md'>{error}</div> : <div className='py-3'></div>}
        <div><button type="submit" class="btn-main w-full uppercase" disabled={isLoading}>{isLoading === false ? 'Save Changes' : <Bounce animating={isLoading}/>}</button></div>
      </form>
    </main>
    </AppLayout>
  )
}
