import '../styles/auth.css'
import AuthLayout from '../components/AuthLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";


export default function ForgotPassword() {

    const [email, setEmail] = useState('');
    const [mailsent, setMailsent] = useState(false);
    const [error, setError] = useState('');

    const { forgotpassword, isLoading } = useContext(AuthContext);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      try {
        if(email !== ""){
        setError('')
        const response = await forgotpassword(email);
        if(response == 'success'){
          setMailsent(true);
        }
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('failed: '+ error)
      }
    }  
    };

  return (
    <AuthLayout>
    <main>
        <section className='max-w-lg mx-auto pt-10'>
            <form onSubmit={handleSubmit} className='authform p-10 lg:px-16 mb-20'>
            {mailsent === false ? 
            <>
                <h3 className='text-white text-2xl font-semibold text-center mb-1'>Reset your password</h3>
                <h6 className='text-neutral-400 text-sm text-center mb-10'>Type in your registered email address to reset password</h6>
                <div className='border-2 fields border-white'>
                    <div class="py-2 px-3">
                        <label for="email">Email Address</label>
                        <input type="email" id="email" placeholder="johndoe@example.com" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    </div>
                </div>
                {error !== "" ? <div className='text-center text-red-500 font-semibold text-md mb-3'>{error}</div> : <div className='py-4'></div>}
                <button type="submit" class="btn-main w-full" disabled={isLoading}>{isLoading === false ? 'Submit' : <Bounce animating={isLoading}/>}</button>
            </>
            : 
            <>
            <h3 className='text-white text-2xl font-semibold text-center mb-4'>Recovery Email Sent!</h3>
            <div className='text-neutral-400 text-center'>Please check your email. We have sent a password recovery instructions. Verification link will expire in 5 minutes.</div>
            <div class="btn-main w-full uppercase text-center mt-10">Thank You</div>
            </>
            }
            </form>
        </section>
    </main>
    </AuthLayout>
  )
}
