import React from 'react';
import Header from './Header';
import Footer from './Footer';

const HomeLayout = ({ children }) => {
  return (
    <html lang="en">
      <body className='max-w-screen-2xl mx-auto'>
        <Header />
        {children}
        <Footer />
      </body>
    </html>
  )
}

export default HomeLayout;