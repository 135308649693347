import '../styles/auth.css'
import AuthLayout from '../components/AuthLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";

export default function SignUp() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
    const [isAccepted, setIsAccepted] = useState(true);
    const [error, setError] = useState('');

    const { signup, isLoading, registered } = useContext(AuthContext);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      if (!type) {
        setError('Please select an account type')
        return;
      }
      else if (!isAccepted) {
        setError('Please accept the terms of service');
        return;
      }
      else if (password.length < 6) {
        setError('Password must have at least 6 characters');
        return;
      }
      try {
        if(email !== "" && name !== "" && password !== ""){
        setError('')
        const response = await signup(name, email, password, type);
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('Signup failed: '+ error)
      }
    }  
    };

  return (
    <AuthLayout>
    <main>
        <section className='max-w-lg mx-auto pt-10'>
            <form onSubmit={handleSubmit} className='authform p-10 lg:px-16 mb-20'>
            {registered === true ? 
            <>
                <h3 className='text-white text-2xl font-semibold text-center mb-4'>Registered Successfully</h3>
                <div className='text-neutral-400 text-center'>You have successfully registered! Please check your email for a verification link. Click on the link to verify your account.</div>
                <div class="btn-main w-full uppercase text-center mt-10">Thank You</div>
            </>
            : 
            <>
                <h3 className='text-white text-2xl font-semibold text-center mb-4'>Sign up</h3>
                <div class="radios pt-2 mb-6">
                    <div class="radio">
                        <input type="radio" id="Buyer" value="Buyer" name="type" checked={type === 'Buyer'} onChange={(e) => setType(e.target.value)}/>
                        <label class="radiolabel" for="Buyer">
                            <div class="checker"></div>
                            Buyer
                        </label>
                    </div>
                    <div class="radio">
                        <input type="radio" id="Seller" value="Seller" name="type" checked={type === 'Seller'} onChange={(e) => setType(e.target.value)}/>
                        <label class="radiolabel" for="Seller">
                            <div class="checker"></div>
                            Seller
                        </label>
                    </div>
                </div>

                <div className='border-2 fields border-white mb-5'>
                    <div className='py-2 px-3'>
                        <label for="name">Your Name</label>
                        <input type="name" id="name" placeholder="John" value={name} onChange={(e) => setName(e.target.value)} required/>
                    </div>
                    <div class="py-2 px-3 border-t-2 border-b-2 border-white">
                        <label for="email">Email Address</label>
                        <input type="email" id="email" placeholder="johndoe@example.com" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    </div>
                    <div className='py-2 px-3'>
                        <label for="password">Password</label>
                        <input type="password" id="password" placeholder='******' value={password} onChange={(e) => setPassword(e.target.value)} required/>
                    </div>
                </div>
                {error !== "" ? <div className='text-center text-red-500 font-semibold text-md mb-4'>{error}</div> : <div className='py-5'></div>}
                <div class="flex items-start mb-5">
                    <div class="flex items-center h-5">
                    <input id="remember" type="checkbox" value="" checked={isAccepted} onChange={(e) => setIsAccepted(!isAccepted)} class="w-4 h-4 border border-gray-300 rounded bg-transparent focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required/>
                    </div>
                    <label for="remember" class="ml-2 pt-0.5 text-xs text-gray-400"> I agree to the Terms of Service and Privacy Policy</label>
                </div>
                <button type="submit" class="btn-main w-full uppercase" disabled={isLoading}>{isLoading === false ? 'Create an account' : <Bounce animating={isLoading}/>}</button>
            </>
            }
            </form>
        </section>
    </main>
    </AuthLayout>
  )
}
