import React from 'react';
import AppHeader from './AppHeader';
import Footer from './Footer';

const AuthLayout = ({ children, nopadding }) => {
  return (
    // <html lang="en">
    //   <body>
        <>
        <AppHeader />
        <div class={`${nopadding ? 'p-0' : 'p-6 pt-0 md:p-7 lg:p-12'} sm:ml-64 flex flex-col min-h-screen`}>
        {children}
        </div>
        {/* <Footer /> */}
        </>
      // </body>
    // </html>
  );
};

export default AuthLayout;