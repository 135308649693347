import React, { Fragment, useContext, useState, useEffect } from 'react';
import '../styles/home.css'
import { Link, useParams, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { AppContext } from '../contexts/AppContext';
import HomeLayout from '../components/HomeLayout';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { FiArrowRight } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { HiStar, HiOutlineStar } from "react-icons/hi";

import { Dialog, RadioGroup, Transition } from '@headlessui/react'

export default function Products() {
    const isLoggedIn = !!localStorage.getItem('userToken');

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchtxt = (searchParams.get('search') != null) ? searchParams.get('search') : '';

    const productParams = new URLSearchParams(location.search);
    const producttxt = searchParams.get('product');

    const [currentSlide, setCurrentSlide] = useState(0);
  
    const nextSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide === 2 ? 0 : prevSlide + 1));
    };
  
    const previousSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide === 0 ? 2 : prevSlide - 1));
    };

    // const { searchtxt } = useParams();
    const [search, setSearch] = useState(searchtxt);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState([]);
    const [open, setOpen] = useState(false);

    const { searchproducts, searchloading, isLoading, logout } = useContext(AuthContext);

    const { getproduct } = useContext(AppContext);
    
    useEffect(() => {
        setSearch(searchtxt);
        const searchProducts = async (e) => {
            const response = await searchproducts(searchtxt);
            setProducts(response);
        }
        searchProducts()
    }, [searchtxt]);
    
    useEffect(() => {
        if(producttxt != null){
            getproductmodal(producttxt)
        }
    }, [producttxt]);

    const getproductmodal = async (productid) => {
        const response = await getproduct({ productid: productid });
        setProduct(response[0]);
        if(response[0] != ""){
            setOpen(true)
            setCurrentSlide(0)
        }
    }

    const handleSearch = async (e) => {
      const searchTerm = e.target.value;
      setSearch(searchTerm);
  
      const response = await searchproducts(searchTerm);
      setProducts(response);
    };

  return (
    <HomeLayout>
        <main>
            <section className='py-5 mt-5 text-center container mx-auto mb-20'>
                <div className='heading'>Explore Products</div>
                <div class="block lg:flex items-center justify-between mt-4 mb-10">
                    <div className='lg:w-1/3 md:w-2/2'><h5 class="mb-0 text-xl font-semibold text-white text-left">Showing {products.length} products</h5></div>
                    <div className='lg:w-1/3 md:w-2/2'>
                    <div class="relative w-full">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            {searchloading === true ? <Spinner className='w-5 h-5 text-neutral-300'/> : <svg class="w-5 h-5 text-neutral-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>}
                            <span class="sr-only">Search icon</span>
                        </div>
                        <input type="text" id="search-navbar" class="block w-full p-3 pl-10 text-sm" placeholder="Search product title" value={search} onChange={handleSearch}/>
                    </div>
                    </div>
                </div>
                
                {
                isLoading === false ? 
                <>
                { 
                products.length > 0 ? <>
                <div class="grid lg:grid lg:grid-cols-3 grid-cols-1 gap-4 gap-y-4">
                {
                    products.map((product, index) => {
                    return(
                        <div key={index} className='flex-1'>
                        <div onClick={() => getproductmodal(product.productid)} className=" bg-neutral-700 rounded-lg shadow h-full flex flex-col text-left cursor-pointer hover:bg-neutral-800">
                            <img class="rounded-t-lg object-cover w-full" src={`data:image/png;base64,${product.image0}`} alt={product.name} style={{height:'30rem'}}/>
                            <div class="p-5 flex flex-1 flex-col justify-between">
                                <div>
                                <h5 class="mb-2 text-2xl font-semibold text-white">{product.name}</h5>
                                <p class="mb-3 text-sm text-neutral-400 text-left">{product.shortdescription}</p>
                                </div>
                                <div class="flex items-center justify-between mt-5">
                                    <span class="text-2xl font-semibold text-white">${product.price}</span>
                                    <Link to={`/product/${product.productid}`}><img src='/images/icons/Redo.png'/></Link>
                                </div>
                            </div>
                        </div>
                        </div>
                    )
                    }) 
                }
                </div>
                </>
                : <div className='text-neutral-500 text-center text-xl font-medium tracking-wide'>No Products found</div>
                }
                </>
                : 
                <div className='flex justify-center text-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div>
                }
            </section>



            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        enterTo="opacity-100 translate-y-0 md:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 md:scale-100"
                        leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                        <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-7xl">
                            <div className="relative flex w-full items-center overflow-hidden bg-white rounded-lg px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                            <button
                                type="button"
                                className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                                onClick={() => setOpen(false)}>
                                <span className="sr-only">Close</span>
                                <RxCross2 className="h-6 w-6" aria-hidden="true" />
                            </button>
                            { producttxt != "" && product != '' &&
                            <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                                <div className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">




                                <div className="relative">
                                    <button className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={previousSlide}>
                                        <span class="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-neutral-800/30 group-hover:bg-white/50 dark:group-hover:bg-neutral-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-neutral-800/70 group-focus:outline-none">
                                            <svg aria-hidden="true" class="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-neutral-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                                            <span class="sr-only">Previous</span>
                                        </span>
                                    </button>
                                    <button className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={nextSlide}>
                                            <span class="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-neutral-800/30 group-hover:bg-white/50 dark:group-hover:bg-neutral-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-neutral-800/70 group-focus:outline-none">
                                            <svg aria-hidden="true" class="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-neutral-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                                <span class="sr-only">Next</span>
                                            </span>
                                    </button>
                                    <div className="w-full relative" style={{height:'30rem'}}>
                                        <img src={`data:image/png;base64,${product.image0}`} alt={"Slider 0"} className={`w-full h-full object-center object-cover transition-opacity duration-500 ease-in-out absolute ${ 0 === currentSlide ? 'opacity-100' : 'hidden' }`} />
                                        <img src={`data:image/png;base64,${product.image1}`} alt={"Slider 1"} className={`w-full h-full object-center object-cover transition-opacity duration-500 ease-in-out absolute ${ 1 === currentSlide ? 'opacity-100' : 'hidden' }`} />
                                        <img src={`data:image/png;base64,${product.image2}`} alt={"Slider 2"} className={`w-full h-full object-center object-cover transition-opacity duration-500 ease-in-out absolute ${ 2 === currentSlide ? 'opacity-100' : 'hidden' }`} />
                                    </div>
                                    </div>



                                
                                </div>
                                <div className="sm:col-span-8 lg:col-span-7">
                                <h2 className='text-3xl text-neutral-900 font-semibold'>{product.name}</h2>
                                <hr className='my-2'/>
                                <div className='flex flex-row justify-between space-x-4'>
                                    <h2 className='text-2xl text-neutral-800 font-semibold mt-1 mb-2'>${(product.price).toLocaleString()}</h2>
                                    <div className='flex flex-row items-center'>
                                    { product.totalreviews > 0 && Array(5).fill(<div>Element</div>).map((element, index) => (
                                        index < product.rating ? <HiStar size={20} color='#EDAE49' key={index}/> : <HiStar size={20} color='#1E293B' key={index}/>
                                    ))}
                                    <div className='text-gray-500 font-medium tracking-wide text-sm pl-2'>{product.totalreviews} reviews</div>
                                    </div>
                                </div>
                                <div className='text-sm text-gray-500 font-normal leading-relaxed'>
                                {product.description}
                                </div>
                                <div className='mt-6'><Link to={ isLoggedIn ? `/checkout/${product.productid}` : `/login?redirect=/product/${product.productid}`} className='btn-main !block text-center !rounded-md !w-full !font-semibold'>Book</Link></div>
                                </div>
                            </div>
                            } 
                            </div>
                        </Dialog.Panel>
                        </Transition.Child>
                    </div>
                    </div>
                </Dialog>
            </Transition.Root>


        </main>
    </HomeLayout>
  )
}
