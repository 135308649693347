import './App.css';
import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import Layout from './components/Layout';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './components/PrivateRoute'; // Import the PrivateRoute component
import Product from './pages/Product';
import Checkout from './pages/Checkout';
import Orders from './pages/Orders';
import Settings from './pages/Settings';
import UpdatePassword from './pages/UpdatePassword';
import AddListing from './pages/AddListing';
import { BuyerProvider } from './contexts/BuyerContext';
import { SellerProvider } from './contexts/SellerContext';
import Chat from './pages/Chat';
import Order from './pages/Order';
import Sales from './pages/Sales';
import Redeem from './pages/Redeem';
import Wallet from './pages/Wallet';
import Products from './pages/Products';
import ResetPassword from './pages/ResetPassword';
import Verify from './pages/Verify';

function App() {

  return (
    <Router>
      <Routes>
          {/* <Route path="/" element={<Layout />}> */}
            <Route path="/" index element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/verify/:token" element={<Verify />} />

            <Route path="/products/" element={ <Products />} />
            {/* <Route path="/products/:searchtxt" element={ <Products />} /> */}
            <Route path="/products/:productid" element={ <Products />} />

            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            {/* <PrivateRoute path="/dashboard" element={<Dashboard />} /> */}

            {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
          {/* </Route> */}
          <Route path="/dashboard" element={ <PrivateRoute> <Dashboard /> </PrivateRoute> } />
          <Route path="/add-listing" element={ <PrivateRoute allowedto={['Seller']}> <AddListing /> </PrivateRoute> } />
          {/* <Route path="/product" allowedto={['Buyer']} element={ <PrivateRoute> <Product /> </PrivateRoute> } /> */}
          <Route path="/product/:productId" element={ <PrivateRoute> <Product /> </PrivateRoute> } />
          <Route path="/checkout/:productId" element={ <PrivateRoute allowedto={['Buyer']}> <Checkout /> </PrivateRoute> } />
          <Route path="/chat" element={ <PrivateRoute> <Chat /> </PrivateRoute> } />
          <Route path="/chat/:userId" element={ <PrivateRoute> <Chat /> </PrivateRoute> } />
          <Route path="/orders" element={ <PrivateRoute allowedto={['Buyer']}> <Orders /> </PrivateRoute> } />
          <Route path="/order/:orderId" element={ <PrivateRoute allowedto={['Buyer']}> <Order /> </PrivateRoute> } />
          <Route path="/sales" element={ <PrivateRoute allowedto={['Seller']}> <Sales /> </PrivateRoute> } />
          <Route path="/redeem" element={ <PrivateRoute allowedto={['Seller']}> <Redeem /> </PrivateRoute> } />
          <Route path="/wallet" element={ <PrivateRoute allowedto={['Seller']}> <Wallet /> </PrivateRoute> } />
          <Route path="/settings" element={ <PrivateRoute> <Settings /> </PrivateRoute> } />
          <Route path="/update-password" element={ <PrivateRoute> <UpdatePassword /> </PrivateRoute> } />
        </Routes>
    </Router>
  );
}

export default App;
