import AppLayout from '../components/AppLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import '../styles/orders.css'
import { TbExternalLink } from 'react-icons/tb';
import { BsArrowRight } from "react-icons/bs";
import { FaStripe } from "react-icons/fa";
import BASE_URL from '../config';
import axios from 'axios';


export default function Wallet() {

    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const [sales, setSales] = useState([]);
    const [stats, setStats] = useState([]);
    const [isLoading2, setIsLoading2] = useState(false);

    const { connectstripe, getmywallet, getmywalletstats, isLoading } = useContext(AppContext);

    const navigate = useNavigate();
    const userToken = JSON.parse(localStorage.getItem('userToken'));

    const [stripeId, setStripeId] = useState(userToken.stripeid);
    const [stripeEmail, setStripeEmail] = useState(userToken.stripemail);

    const connectStripeAccount = async () => {
      try {
        const response = await axios.post('https://justsell.developmint.xyz/api/stripe/connect', {userId: userToken.userid});
  
        if (response.data.success) {
          const { url } = response.data;
          // Redirect the user to the Stripe account connection URL
          window.location.href = url;
        } else {
          alert('Failed to connect Stripe account');
        }
      } catch (error) {
        alert('Error occurred during Stripe account connection:'+ error);
      }
    };
  
    const handleWithdrawal = async () => {
      try {
        const response = await axios.post('/api/stripe/withdraw', {
          stripeId,
          stripeEmail,
          // amount: withdrawAmount,
        });
  
        if (response.data.success) {
          console.log('Withdrawal successful');
          // Perform any necessary UI updates or show success message
        } else {
          console.error('Failed to withdraw funds');
          // Show error message or handle the failure case
        }
      } catch (error) {
        console.error('Error occurred during withdrawal:', error);
      }
    };

    useEffect(() => {
      const fetchMySales = async () => {
          const response = await getmywallet();
          setSales(response);
      }

      const fetchMyStats = async () => {
          const response = await getmywalletstats();
          setStats(response);
      }

      fetchMySales();
      fetchMyStats();
    }, []);

    // const stats = [
    //     { id: 1, name: 'Available to Withdraw', value: '$200' },
    //     { id: 2, name: 'Withdrawn to date', value: '$0' },
    //     { id: 3, name: 'To be redeemed', value: '$100' },
    // ]

  return (
    <AppLayout>
    <main>
      <div className='flex justify-between items-center mb-3'>
        <h3 className='text-white text-3xl font-semibold'>Wallet</h3>
      </div>
      {
        isLoading === false ? 
        <>
        <div>
            <dl className="grid grid-cols-1 gap-x-3 gap-y-16 text-center lg:grid-cols-3">
            {stats && stats.map((stat) => (
                <div key={stat.id} className="mx-auto flex w-full flex-col gap-y-4 bg-black p-5 py-10 rounded-lg mb-4 border border-gray-500">
                <dt className="text-base font-medium leading-7 text-neutral-400">{stat.name}</dt>
                <dd className="order-first text-4xl font-semibold tracking-tight text-white sm:text-6xl">{stat.value}</dd>
                </div>
            ))}
            </dl>
        </div>
        <div className='mb-4'>
          { (userToken.stripeid === null) ? <button onClick={connectStripeAccount} className='btn-main flex items-center !font-semibold !text-neutral-900 !rounded-lg'><FaStripe size={34} className="mr-1"/> | Connect Account <BsArrowRight className='ml-2'/></button> : <button className='btn-main flex items-center'>Withdraw | <FaStripe size={34} className="mr-1"/>:{userToken.stripemail}</button> }
        </div>
          { (sales && sales.length > 0) ?
          <div className='space-y-3'>
          {
            sales.map((order, index) => {
              const formattedDate = new Date(order.addedat).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
              return(
                <div key={index} className='lg:flex items-center justify-between order px-5'>
                  <div className='grid lg:w-2/3 lg:grid-cols-4 md:grid-cols-2 md:w-full lg:gap-y-0 md:gap-y-5 sm:gap-y-6 py-5'>
                    <div><h6>Name</h6><p className='text-md flex items-center'>{order.productname} <Link className='text-blue-500 ml-2' to={`/product/${order.productid}`}><TbExternalLink size={18}/></Link></p></div>
                    <div><h6>Date</h6><p>{formattedDate}</p></div>
                    <div><h6>Amount</h6><p>${order.productprice}</p></div>
                    <div><h6>Buyer</h6><p>{order.buyer}</p></div>
                  </div>
                  <div className='flex lg:w-auto md:w-full pb-5 lg:pt-5 md:pt-0'>
                    <div>{order.transferred == 0 ? <button className='btn-open !px-9'>Pending</button> : <button className='bg-green-300 text-black font-semibold px-5 py-3 rounded'>Transferred</button>}</div>
                  </div>
                </div>
              )
            }) 
          }
          </div>
          : <div className='text-neutral-200 text-center text-xl font-medium tracking-wide py-10'>No Sales Found</div> }
        </> 
        : 
        <div className='flex justify-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div>
        }
    </main>
    </AppLayout>
  )
}
