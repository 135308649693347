import AppLayout from '../components/AppLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FiInfo } from 'react-icons/fi';
import { MdSend } from 'react-icons/md';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import '../styles/chat.css'
import ScrollToBottom from "react-scroll-to-bottom";

import BASE_URL from '../config';
import io from 'socket.io-client';
const socket = io.connect(BASE_URL.replace('/api',''));

export default function Chat() {
    const { userId } = useParams();
    const userToken = JSON.parse(localStorage.getItem('userToken'));
    const userTokenid = userToken.userid;

    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const [chatuser, setChatuser] = useState('');

    const { getchatuser, isLoading } = useContext(AppContext);

    const navigate = useNavigate();

    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState('');
    const [conversationList, setConversationList] = useState([]);

    useEffect(() => {
      const fetchChatUser = async () => {
        const response = await getchatuser({ userid: userId });
        setChatuser(response[0]);
      };

      if(userId){
        fetchChatUser()
      }
    
      // Fetch old chat messages
      socket.emit('fetch messages', {
        sender: userToken.userid,
        receiver: userId,
      });
    
      // Receive old chat messages
      socket.on('old messages', (data) => {
        setMessages(data);
      });
    
      // Receive new chat message
      socket.on('chat message', (data) => {
        const { sender, receiver, message } = data;
    
        // Check if the received message is for the selected conversation
        if (
          (sender === userId && receiver === userToken.userid) ||
          (sender === userToken.userid && receiver === userId)
        ) {
          setMessages((prevMessages) => [...prevMessages, data]);
        }
    
        // Check if the conversation already exists in the list
        const existingConversation = conversationList.find(
          (conversation) =>
            conversation.userid === sender || conversation.userid === receiver
        );
    
        // If the conversation exists and the sender is the logged-in user, update the last message and timestamp
        if (existingConversation && sender === userToken.userid) {
          const updatedConversation = {
            ...existingConversation,
            message,
            timestamp: data.addedat,
          };
    
          // Move the conversation to the top of the list
          setConversationList((prevList) => {
            const updatedList = prevList.filter(
              (conversation) =>
                conversation.userid !== sender && conversation.userid !== receiver
            );
            return [updatedConversation, ...updatedList];
          });
        } else {
          // Fetch the updated conversation list
          socket.emit('get conversation list', { userId: userTokenid });
        }
      });
    
      // Emit 'get conversation list' event to server
      socket.emit('get conversation list', { userId: userTokenid });
    
      // Listen for 'conversation list' event from server
      socket.on('conversation list', (data) => {
        setConversationList(data);
      });
    
      return () => {
        socket.off('chat message');
        socket.off('old messages');
        socket.off('conversation list');
      };
    }, [userId, userTokenid]);
    
    
    // Send a chat message
    const sendMessage = async () => {
        if (messageText.trim() !== '') {
        const data = {
            sender: userToken.userid,
            receiver: userId,
            message: messageText.trim()
        };
        await socket.emit('chat message', data);
        // setMessages(prevMessages => [...prevMessages, message]);
        setMessageText('');
        }
    };

  const users = [
    { id: 1, name: 'John', unread: 2, message: 'Good work', },
    { id: 2, name: 'Alice', unread: 0, message: 'I have done my work' },
    { id: 3, name: 'Bob', unread: 0, message: 'what\'s the final price' },
  ];

  return (
    <AppLayout nopadding={'yes'}>
    <main className='relative'>
    <div className="flex h-screen">
      <div className="lg:w-3/12 md:1/2 bg-neutral-800 px-3 py-4">
        {/* User List */}
        <h2 className="text-xl text-white font-semibold mb-4">Message</h2>
        <div className="space-y-4">
          {conversationList.map((conversation) => {
          return(
            <div>
            <Link to={`/chat/${conversation.userid}`}>
              <div
                  className={`rounded-lg py-3 px-4 flex flex-row items-center justify-start ${conversation.userid == userId ? 'bg-main' : 'bg-neutral-700'}`}
                  id={conversation.userid}
                  key={conversation.userid}>
                  <div className='w-3/12'><img
                      src='https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png'
                      className='h-12 w-12 object-contain'/></div>
                  <div className='w-9/12 lg:pl-1 md:pl-2 flex-1'>
                      <div className='flex flex-row justify-between space-x-4 items-center'>
                          <h6 className='font-semibold text-white'>{conversation.name}</h6>
                          <div>{conversation.unread > 0
                          ? <div
                                  className='text-xs w-5 h-5 rounded-full text-black inline-flex items-center justify-center font-semibold'
                                  style={{
                                  backgroundColor: '#EDAE49'
                              }}>{conversation.unread}</div>
                          : ''}</div>
                      </div>
                      <p className={` text-sm w-full truncate ${conversation.userid == userId ? 'text-neutral-200' : 'text-neutral-500 font-medium'}`}>{conversation.message}</p>
                  </div>
              </div>
            </Link>
            </div>
          )}
          )}
        </div>
      </div>
      <div className="lg:w-9/12 md:1/2 bg-black min-h-screen flex flex-col justify-between">
        {/* Chat Window */}
        { userId ? 
        <>
        <div className='bg-neutral-800 p-3 border-l-4 border-l-black flex justify-between items-center w-full'>
            <div className='flex space-x-3 items-center'>
            <div><img src='https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png' className='h-12 w-12 object-contain'/></div>
            <div className='flex flex-col justify-center'><h6 className='text-lg text-white font-semibold'>{chatuser.name}</h6>
            {/* <span className='text-xs tracking-wide font-medium' style={{color: '#EDAE49'}}>Online</span> */}
            </div>
            </div>
            <div><FiInfo color='#CCCCCC' size={28}/></div>
        </div>
        <ScrollToBottom className='flex flex-1 w-full overflow-y-hidden mb-3 chatbox'>
        <div className="p-4 text-neutral-200 w-full">
          <div className='space-y-2'>
            {messages.map((message, index) => {
              const formattedDate = new Date(message.addedat).toLocaleTimeString([], { timeStyle: 'short' });
              return(
              <>
              {
              message.sender == userToken.userid ? 
              <div className='w-2/3 flex mr-auto justify-start' key={index}>
                  <div className='flex flex-row w-full space-x-1 items-end'>
                  <div className='py-2 rounded-r-xl rounded-b-xl bg-neutral-800 text-neutral-300 text-sm pl-4 pr-5 font-medium'>{message.message}</div>
                  <span className='text-xs text-neutral-600'>{formattedDate}</span>
                </div>
              </div>
              : 
              <div className='w-2/3 flex ml-auto justify-end' key={index}>
              <div className='flex flex-row w-full space-x-1 items-end justify-end'>
                <span className='text-xs text-neutral-600'>{formattedDate}</span>
                <div className='py-2 rounded-l-xl rounded-t-xl bg-neutral-800 text-neutral-300 text-sm pr-4 pl-5 font-medium'>{message.message}</div>
              </div>
              </div>
              }
              </>
            )}
            )}
          </div>
        </div>
        </ScrollToBottom>
        <div className="flex px-3 pb-3 w-full">
          <input type="text" className="flex-1 bg-neutral-800 p-3 rounded-lg mr-2 text-white" placeholder="Type your message" autoFocus value={messageText} onChange={e => setMessageText(e.target.value)} onKeyPress={(event) => { event.key === "Enter" && sendMessage() }}/>
          <button type="button" className="bg-main text-black p-3 rounded-lg" onClick={sendMessage}><MdSend color='#000' size={22}/></button>
        </div>
        </>
        : 
        <div className='flex flex-1 w-full justify-center items-center text-neutral-500 font-medium'>Please select a user to start the conversation.</div>
        }
      </div>
    </div>
    </main>
    </AppLayout>
  )
}
