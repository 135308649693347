import AppLayout from '../components/AppLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import '../styles/settings.css'
import { HiArrowNarrowRight } from 'react-icons/hi';

export default function Settings() {

    const userToken = JSON.parse(localStorage.getItem('userToken'));
    const [name, setName] = useState(userToken.name);
    const [email, setEmail] = useState(userToken.email);
    const [error, setError] = useState('');

    const { updateprofile, isLoading } = useContext(AppContext);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      try {
        if(name !== "" && email !== ""){
        setError('')
        const response = await updateprofile(name, email);
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('Update failed: '+ error)
      }
    }  
    };

  return (
    <AppLayout>
    <main className='settings'>
      <div className='flex justify-between items-center mb-3'>
        <h3 className='text-white text-3xl font-semibold'>Settings</h3>
      </div>
      <form onSubmit={handleSubmit} className='space-y-5 lg:w-1/2 md:w-full'>
        <div>
            <label for="name">Your Name</label>
            <input type="name" id="name" placeholder="John" value={name} onChange={(e) => setName(e.target.value)} required/>
        </div>
        <div>
            <label for="email">Email Address</label>
            <input type="email" id="email" placeholder="johndoe@example.com" value={email} onChange={(e) => setEmail(e.target.value)} required/>
        </div>
        {error !== "" ? <div className='text-center text-red-500 font-semibold text-md'>{error}</div> : <div className='py-3'></div>}
        <div className='flex flex-row space-x-3'>
          <div className='w-1/2'><button type="submit" class="btn-main w-full uppercase" disabled={isLoading}>{isLoading === false ? 'Save Changes' : <Bounce animating={isLoading}/>}</button></div>
          <div className='w-1/2'><Link to={"/update-password"} class="btn-main-inverse w-full uppercase flex flex-row items-center justify-center">Update Password <HiArrowNarrowRight className='ml-2' size={16} /></Link></div>
        </div>
      </form>
    </main>
    </AppLayout>
  )
}
