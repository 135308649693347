import AppLayout from '../components/AppLayout'
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FiArrowLeft, FiDivide } from "react-icons/fi";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { HiStar, HiOutlineStar } from "react-icons/hi";
import {FaRegCommentDots} from "react-icons/fa"

import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import { RxCopy, RxCross2 } from 'react-icons/rx';
import {SlActionRedo} from "react-icons/sl"

import BASE_URL from '../config';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

import { FacebookShareButton, RedditShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { BsFacebook, BsTwitter, BsWhatsapp, BsReddit, BsCheckCircleFill } from 'react-icons/bs';

const stripePromise = loadStripe('pk_test_0erQNzYrhNahOtVUMB5L3kKV00Fbp33J0X');

export default function Product() {
    const { productId } = useParams();

    const { getproduct, placetemporder, isLoading } = useContext(AppContext);
    const [isLoading2, setIsLoading2] = useState(false);
    const [modalopen, setModalopen] = useState(false);
    const [product, setProduct] = useState('');
    const [isCopied, setIsCopied] = useState(false);

    const sharelink = 'https://justsell.developmint.xyz/products?product='+productId;
    const sharetext = 'Check out this amazing product on JustSell platform!';
  
    const copyToClipboard = () => {
      navigator.clipboard.writeText(sharelink);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Clear the success message after 2 seconds
    };

    const navigate = useNavigate();

    useEffect(() => {
      // Call the function and update the state when the component mounts
      fetchProduct();
    }, []);
    
    const fetchProduct = async () => {
        const response = await getproduct({ productid: productId });
        // Update the state with the received products
        setProduct(response[0]);
    }
    
    const handleBookorder = async () => {
        const response = await placetemporder(productId);
        if(response == 'success'){
            navigate('/orders');
        }
    }

    const handleCheckout = async () => {
      try {
        const response = await axios.post(`${BASE_URL}/create-checkout-session`, {
          productId: productId
        });

        const { sessionId } = response.data;
  
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });
  
        if (error) {
          console.error('Stripe error:', error);
        }
      } catch (error) {
        console.error('Checkout error:', error);
      }
    };

    // const handleSubmit = async (e) => {
    //   e.preventDefault();
    //   if(isLoading === false){
    //   try {
    //     if(email !== "" && password !== ""){
    //     setError('')
    //     const response = await login(email, password);
    //     if(response == 'success'){
    //         navigate('/');
    //     }
    //     }
    //     else{
    //         setError('All fields are required')
    //         return;
    //     }
    //   } catch (error) {
    //         setError('Login failed: '+ error)
    //   }
    // }  
    // };
    const pc = [(product.image0),(product.image1),(product.image2)];
  return (
    <AppLayout>
    <main>
      <div class="mb-5 flex justify-start">
          <Link className='text-white flex items-center space-x-2 font-medium' onClick={() => navigate(-1)}><FiArrowLeft className='text-white' size={16}/> <span>Back</span></Link>
      </div>
      {
      isLoading === false ?
      <>
      {
      product !== "" ? 
      <>
      <div class="flex space-x-8">
        <div className='w-1/3 flex-1'>
          <div className="  h-full">
            <img class="w-full object-cover h-full flex-1 rounded-lg shadow" src={`data:image/png;base64,${product.image0}`} alt="" />
          </div>
        </div>
        <div className='w-1/3 flex-1'>
          <div className="  h-full">
            <img class="w-full object-cover h-full flex-1 rounded-lg shadow" src={`data:image/png;base64,${product.image1}`} alt="" />
          </div>
        </div>
        <div className='w-1/3 flex-1'>
          <div className="  h-full">
            <img class="w-full object-cover h-full flex-1 rounded-lg shadow" src={`data:image/png;base64,${product.image2}`} alt="" />
          </div>
        </div>
      </div>
      <div className='flex space-x-16 mt-8'>
        <div className='w-2/3 space-y-3'>
          <h2 className='text-4xl text-white font-medium'>{product.name}</h2>
          <div className='text-lg text-gray-300 font-medium'>City, State</div>
          <div>
            <div className='text-md text-gray-300 font-medium'>Description</div>
            <div className='text-md text-gray-500 font-normal leading-relaxed mt-4'>
              {product.description}
            </div>
          </div>
        </div>
        <div className='w-1/3 space-y-3 lg:px-4'>
          <h2 className='text-4xl text-white font-medium'>${(product.price).toLocaleString()}</h2>
          <div className='flex flex-row items-center space-x-1'>
          {product.totalreviews > 0 && Array(5).fill(<div>Element</div>).map((element, index) => (
            index < product.rating ? <HiStar size={20} color='#EDAE49' key={index}/> : <HiStar size={20} color='#1E293B' key={index}/>
          ))}
          <div className='text-gray-500 font-medium tracking-wide text-sm pl-2'>{product.totalreviews} reviews</div>
          </div>
          <div><Link className='text-white text-lg flex items-center space-x-3 font-normal tracking-wide' onClick={() => setModalopen(true)}><SlActionRedo className='text-white' size={18}/> <span>Share</span></Link></div>
          <div><Link to={`/chat/${product.user}`} className='text-white text-lg flex items-center space-x-3 font-normal tracking-wide'><FaRegCommentDots className='text-white' size={18}/> <span>Chat</span></Link></div>
          <div className='!mt-6'><Link to={`/checkout/${product.productid}`} className='btn-main !block text-center !rounded-md !w-full !font-semibold'>Book</Link></div>
        </div>
      </div>
      </>
      :
      <></>
      }
      </>
      : 
      <div className='flex justify-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div>
      }
    </main>
    
    <Transition.Root show={modalopen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setModalopen}>
          <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          >
          <div className="fixed inset-0 hidden bg-black bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
              <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-lg md:px-4 lg:max-w-lg">
                  <div className="relative w-full items-center overflow-hidden bg-neutral-900 text-white shadow-2xl p-8">
                  <div className='w-full'>
                    <h5 className='text-2xl font-medium mb-2'>Share your listing</h5>
                    <h5 className='text-md text-neutral-400 font-light'>How would you like to share this?</h5>
                  <button
                      type="button"
                      className="absolute top-3 right-3 text-gray-400 hover:text-gray-500"
                      onClick={() => setModalopen(false)}>
                      <span className="sr-only">Close</span>
                      <RxCross2 className="h-5 w-5" aria-hidden="true" />
                  </button>
                  </div>
                  <div className='px-3 py-7'>
                    <div className='grid grid-cols-4 gap-5 text-center justify-center text-xs text-neutral-300 font-light tracking-wider'>
                      <div>
                        <TwitterShareButton url={sharelink} quote={sharetext}>
                          <div className='mx-auto mb-2 p-4 w-14 h-14 items-center justify-center flex bg-neutral-800 rounded-full'>
                            <BsTwitter className='text-white' size={22}/>
                          </div>
                        </TwitterShareButton>
                        <span>Twitter</span>
                      </div>
                      <div>
                        <FacebookShareButton url={sharelink} quote={sharetext}>
                          <div className='mx-auto mb-2 p-4 w-14 h-14 items-center justify-center flex bg-neutral-800 rounded-full'>
                            <BsFacebook className='text-white' size={22}/>
                          </div>
                        </FacebookShareButton>
                        <span>Facebook</span>
                      </div>
                      <div>
                        <RedditShareButton url={sharelink} quote={sharetext}>
                          <div className='mx-auto mb-2 p-4 w-14 h-14 items-center justify-center flex bg-neutral-800 rounded-full'>
                            <BsReddit className='text-white' size={22}/>
                          </div>
                        </RedditShareButton>
                        <span>Reddit</span>
                      </div>
                      <div>
                        <WhatsappShareButton url={sharelink} quote={sharetext}>
                          <div className='mx-auto mb-2 p-4 w-14 h-14 items-center justify-center flex bg-neutral-800 rounded-full'>
                            <BsWhatsapp className='text-white' size={22}/>
                          </div>
                        </WhatsappShareButton>
                        <span>WhatsApp</span>
                      </div>
                    </div>
                    <div className='mt-10'>
                      <div className="flex items-center relative">
                      <input
                        type="text"
                        value={sharelink}
                        className="w-full p-3 bg-neutral-800 border border-neutral-700 text-md text-neutral-400 font-normal"
                        readOnly
                      />
                      <div className='absolute z-30 p-6 w-full bg-gradient-to-r to-90% from-transparent to-neutral-800'></div>
                      {isCopied ?
                      <button className="absolute right-1 top-1 p-2 text-green-500 z-50"><BsCheckCircleFill size={24}/></button>
                      :
                      <button onClick={copyToClipboard} className="absolute right-1 top-1 p-2 text-neutral-500 z-50 hover:text-neutral-300"><RxCopy size={24}/></button>
                      }
                    </div>
                    </div>
                  </div>
                  </div>
              </Dialog.Panel>
              </Transition.Child>
          </div>
          </div>
      </Dialog>
    </Transition.Root>
    </AppLayout>
  )
}
